@import url('https://fonts.googleapis.com/css?family=Creepster');
// @import url('https://fonts.googleapis.com/css?family=Bangers');
// body{
//     font-family: Bangers;
// }

.border-h-t{
    display: block;
    height: 13px;
    background-image: url(./border-h.webp);
    width:100%;
    position: absolute;
    top:-4px;
    left:0;
    z-index: 1;
}
.border-h-b{
    display: block;
    height: 13px;
    background-image: url(./border-h.webp);
    width:100%;
    position: absolute;
    bottom:-4px;
    left:0;
    z-index: 1;
}
.border-v-l{
    display: block;
    width: 12px;
    background-image: url(./border-v.webp);
    height:100%;
    position: absolute;
    top:0;
    left:-2px;
    z-index: 1;
    padding: 0;
}
.border-v-r{
    display: block;
    width: 12px;
    background-image: url(./border-v.webp);
    height:100%;
    position: absolute;
    top:0;
    right:-2px;
    z-index: 1;
    padding: 0;
}
.carousel-control-next, .carousel-control-prev{
    background-color: red;
    width:7%;
    opacity: .8;
}
.vein-content{
    background-color: white;
    padding: 40px;
    position: relative;
    margin: 40px;
    margin-top: 40px;

    h1,h2,h3{
        text-align: center;
    }
}

h1, h2, h3, .h5.card-title {
    font-family: Creepster;
}
.accordion-header button{
    font-size: 22px;
}

h1{
    font-size: 36px;
}
form{
    input.form-control {
        border:0;
        border-bottom: 2px solid black;
        border-radius: 0;
        
    }
    button.btn-primary{
        background-color: red;
        border-radius: 0;
        border: 0;
        text-transform: uppercase;
        font-family: Creepster;
        font-size: xx-large;
    }
}
// form{
//     input{
//         display: block;
//         width:calc(100% - 20px);
//         margin: 20px;
//         font-size: xx-large;
//         border:0;
//         // border-radius: 10px;
//         padding: 10px;
//         border-bottom: 2px solid black;
//     }
//     button{
//         background-color: red;
//         color:white;
//         font-size: x-large;
//         border:0;
//         border-radius: 10px;
//         padding: 10px;
//         text-transform: uppercase;
//         font-weight: bold;
//     }
// }

.hidden-desktop{
    display: none !important;
}
.carousel-wrapper{
    padding:0 0 40px 0;
    .carousel-inner{
        margin: 3px -5px 0 -4px; 
        width: calc(100% + 8px); 
        max-width: calc(100% + 8px);
    }
}
@media only screen and (max-width: 768px) {
    .hidden-mobile{
        display: none !important;
    }
    .hidden-desktop{
        display: block !important;
    }
    .card{
        margin-bottom: 20px;
    }
    .vein-content{
        margin: 20px;
        margin-bottom: 40px;
    }
    
  }